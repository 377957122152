var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-addresses generic-list scroll-container"},[_c('CategoryTitle',{attrs:{"category":_vm.category}}),(
      _vm.userAddresses &&
        _vm.userAddresses.addresses &&
        _vm.userAddresses.addresses.length > 0
    )?_c('v-list',{staticClass:"mt-5"},_vm._l((_vm.userAddresses.addresses),function(address){return _c('v-list-item',{key:address.addressId,staticClass:"white",on:{"click":function($event){return _vm.selectAddress(address)}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-radio-group',{staticClass:"mt-0 pa-0",attrs:{"column":"","hide-details":""},model:{value:(address.selected),callback:function ($$v) {_vm.$set(address, "selected", $$v)},expression:"address.selected"}},[_c('v-radio',{attrs:{"value":1,"color":"default","role":"radio","aria-label":address.addressName}})],1)],1),_c('v-col',{attrs:{"cols":"8 pl-2 pl-sm-0"}},[_c('strong',{staticClass:"d-block"},[_vm._v(_vm._s(address.addressName))]),_c('span',[_vm._v(" "+_vm._s(address.address1)+" "+_vm._s(address.addressNumber)+" - "+_vm._s(address.city)+" ("+_vm._s(address.province)+")")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"main-button mr-2",attrs:{"icon":"","to":{
                  name: 'EditAddress',
                  params: { addressId: address.addressId }
                },"color":"primary","aria-label":"Modifica indirizzo"},on:{"click":function($event){$event.preventDefault();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("$edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica indirizzo")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"secondary-icon-button",attrs:{"icon":"","color":"primary","disabled":address.selected == 1,"aria-label":"Elimina indirizzo"},on:{"click":function($event){$event.stopPropagation();return _vm.removeAddress(address.addressId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina indirizzo")])])],1)],1)],1)}),1):(!_vm.loading)?_c('div',{staticClass:"empty-addresses mb-7"},[_vm._v(" "+_vm._s(_vm.$t("addresses.emptyAddresses"))+" ")]):_vm._e(),_c('v-row',{staticClass:"d-flex justify-space-between footer-btn pl-0",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"color":"primary","large":"","depressed":"","to":{ name: 'EditAddress' },"aria-label":_vm.$t('addresses.addAddressButton')}},[_vm._v(" "+_vm._s(_vm.$t("addresses.addAddressButton"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }