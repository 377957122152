<template>
  <div class="profile-addresses generic-list scroll-container">
    <!-- <v-container> -->
    <CategoryTitle :category="category" />

    <v-list
      class="mt-5"
      v-if="
        userAddresses &&
          userAddresses.addresses &&
          userAddresses.addresses.length > 0
      "
    >
      <v-list-item
        class="white"
        v-for="address in userAddresses.addresses"
        :key="address.addressId"
        @click="selectAddress(address)"
      >
        <!-- :class="{ zebra: index % 2 == 0 }" -->
        <v-row no-gutters align="center">
          <v-col cols="1"
            ><v-radio-group
              v-model="address.selected"
              column
              hide-details
              class="mt-0 pa-0"
              ><v-radio
                :value="1"
                color="default"
                role="radio"
                :aria-label="address.addressName"
              ></v-radio></v-radio-group
          ></v-col>
          <v-col cols="8 pl-2 pl-sm-0">
            <strong class="d-block">{{ address.addressName }}</strong>
            <span>
              {{ address.address1 }} {{ address.addressNumber }} -
              {{ address.city }} ({{ address.province }})</span
            >
          </v-col>
          <v-col cols="3" class="d-flex justify-end">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="main-button mr-2"
                  :to="{
                    name: 'EditAddress',
                    params: { addressId: address.addressId }
                  }"
                  color="primary"
                  @click.prevent
                  aria-label="Modifica indirizzo"
                >
                  <v-icon medium>$edit</v-icon>
                </v-btn>
              </template>
              <span>Modifica indirizzo</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="secondary-icon-button"
                  @click.stop="removeAddress(address.addressId)"
                  color="primary"
                  :disabled="address.selected == 1"
                  aria-label="Elimina indirizzo"
                >
                  <v-icon>$delete</v-icon>
                </v-btn>
              </template>
              <span>Elimina indirizzo</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
    <div v-else-if="!loading" class="empty-addresses mb-7">
      {{ $t("addresses.emptyAddresses") }}
    </div>

    <v-row no-gutters class="d-flex justify-space-between footer-btn pl-0">
      <v-btn
        color="primary"
        large
        depressed
        :to="{ name: 'EditAddress' }"
        :aria-label="$t('addresses.addAddressButton')"
      >
        {{ $t("addresses.addAddressButton") }}
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import DeliveryService from "~/service/deliveryService";
import categoryMixins from "~/mixins/category";

export default {
  name: "ProfileAddress",
  components: { CategoryTitle },
  mixins: [categoryMixins],
  data() {
    return {
      loading: true,
      newAddress: {
        addressId: -1,
        addressName: "",
        address1: "",
        address2: "",
        addressNumber: "",
        postalcode: "",
        city: "",
        province: ""
      },
      userAddresses: []
    };
  },

  methods: {
    async fetchAddresses() {
      this.loading = true;
      try {
        this.userAddresses = await DeliveryService.getWarehousesList("home");
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async removeAddress(addressId) {
      const res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        await DeliveryService.removeAddress(addressId);
        await this.fetchAddresses();
      }
    },
    async selectAddress(address) {
      await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });
      this.fetchAddresses();
    },
    addAddress() {
      // this.$router.push({
      //   path: "/edit-addresses/",
      //   name: "/edit-addresses/",
      //   params: {
      //     address: this.newAddress,
      //     newAddress: true
      //   }
      // });
    }
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchAddresses();
  }
};
</script>
<style lang="scss">
.profile-addresses {
  .v-list-item {
    border: 1px solid var(--v-grey-lighten1) !important;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .main-button {
      height: 30px !important;
      width: 30px !important;
      .v-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;
      }
    }
    .secondary-icon-button {
      height: 30px !important;
      width: 30px !important;
      .v-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
